
import { setContext } from '@apollo/client/link/context';
import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client';



const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('accessToken');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  });
  const httpLink = createHttpLink({
    uri: 'https://airomnishopapi.devssh.xyz/graphql',
  });
//   const uploadLink = createUploadLink({ 
//     uri: "http://139.99.237.187:8000/graphql"
//   });
  
  export const client = new ApolloClient({
    link: from([
      authLink.concat(httpLink),
    //   authLink.concat(uploadLink),
    ]),
    cache: new InMemoryCache(),
  });