import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {

  return useRoutes([
    {
      path: '/',
      element: <Navigate to="/dashboard" replace />,
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
     

   
        {
          path: 'subadmin',
          children: [
            { element: <Navigate to="/dashboard/subadmin/list" replace />, index: true },
            { path: 'list', element: <SubAdminList /> },
            { path: 'create', element: <SubAdminCreate /> },
            { path: 'edit/:id', element: <SubAdminCreate /> },

            
          ],
        },

        {
          path: 'seller',
          children: [
            { element: <Navigate to="/dashboard/seller/list" replace />, index: true },
            { path: 'list', element: <SellerList /> },
            { path: 'create', element: <SellerCreate /> },
            { path: 'edit/:id', element: <SellerCreate /> },

            
          ],
          
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace />, index: true },
            { path: 'list', element: <UserList /> },
            { path: 'create', element: <UserCreate /> },
            { path: 'edit/:id', element: <UserCreate /> },

            
          ],
          
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [

        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));


const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// SuperADmin

// Subadmin
const SubAdminList = Loadable(lazy(() => import('../pages/SuperAdmin/Subadmin/List')));
const SubAdminCreate = Loadable(lazy(() => import('../pages/SuperAdmin/Subadmin/Create')));

// seller
const SellerList = Loadable(lazy(() => import('../pages/SuperAdmin/Seller/List')));
const SellerCreate = Loadable(lazy(() => import('../pages/SuperAdmin/Seller/Create')));

// user
const UserList = Loadable(lazy(() => import('../pages/SuperAdmin/User/List')));
const UserCreate = Loadable(lazy(() => import('../pages/SuperAdmin/User/Create')));